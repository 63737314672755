import './Navbar.js';

import Links from './Links.js';
import './AllGlider.js';

import './Back2Top.js';

new Links();

const footer_year = document.querySelector('#footer_year');
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}
