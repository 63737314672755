import Standard from '../../js/Libraries/Standard';
import jump from 'jump.js';
import Handlers from '../../js/Libraries/Handlers';

class Home extends Standard {
  constructor() {
    super('body');
    if (!super.exist()) return;
    this.hl = new Handlers();

    this.init();
  }

  customLazyloadBackgroundImage() {
    const oneLazyloadBackground = (el) => {
      var isLoaded = false;
      if (el) {
        if (this.hl.elementInViewportAnyPart(el, 400) && isLoaded === false) {
          isLoaded = true;
          el.style.backgroundImage = `url('${el.getAttribute('data-src')}')`;
        }
        window.addEventListener('scroll', () => {
          if (this.hl.elementInViewportAnyPart(el, 400) && isLoaded === false) {
            isLoaded = true;
            el.style.backgroundImage = `url('${el.getAttribute('data-src')}')`;
          }
        });
      }
    };
    const els = document.querySelectorAll('.custom-lazyload-bg');
    els.forEach((el) => {
      oneLazyloadBackground(el);
    });
  }

  init() {
    this.customLazyloadBackgroundImage();
  }

  events() {}
}

new Home();
