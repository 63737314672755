import Handlers from '../../js/Libraries/Handlers';
import Standard from '../../js/Libraries/Standard';

/* 
  -fehler bei textupload in backend, was angezeigt???
  - 'data feld muss ausgefüllt sein, bisher ohne benarchitgn...
  - textarea text richtig abstimmen....
*/
class Navbar extends Standard {
  constructor() {
    super('#navbar');
    if (!super.exist()) return;
    this.body = document.querySelector('body');
    this.burger = super.set('.z-burger');
    this.hl = new Handlers();
    this.pages = super.set('.z-nav-pages');
    this.navLinks = super.set('.z-nav-page', true);
    this.speed = 0.65;
    this.lastScrollTop = 0;

    this.upScollPossible = false;

    this.init();
  }

  init() {
    this.dropdowns();
    this.small_btns();
    this.events();
  }
  events() {
    this.burger.addEventListener('click', () => {
      if (this.pages.dummy) {
        this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
        this.pages.dummy = '';
      } else {
        this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
        this.pages.dummy = 'x';
      }
      this.burger.classList.toggle('z-toggle');
      this.navLinks.forEach((link, index) => {
        var speed_pro = index / 8 + this.speed - 0.35;
        if (link.style.animation) {
          link.style.animation = '';
        } else {
          link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
        }
      });
    });

    window.addEventListener(
      'scroll',
      async () => {
        const isSmallScreen = this.hl.widthOfScreen() < 575;
        if (isSmallScreen);
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (!this.hl.elementInViewportAnyPart(this.container)) {
          this.container.classList.add('navbar-opacity');
        } else {
          this.container.classList.remove('navbar-opacity');
        }

        if (st >= this.lastScrollTop) {
          this.container.classList.remove('active');
        } else {
          this.container.classList.add('active');
        }

        this.lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  }

  small_btns() {
    const btns = super.set('.small-btn', true);
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        const parent = btn.parentNode.parentNode;
        parent.classList.toggle('active');
      });
    });
  }

  dropdowns() {
    const dropdowns = super.set('.dropdown-element', true);

    dropdowns.forEach((drop) => {
      const btn = drop.querySelector('.dropdown-btn');
      btn.addEventListener('mouseover', () => {
        dropdowns.forEach((interDrop) => {
          interDrop.classList.remove('active');
        });
        drop.classList.add('active');
      });
    });

    function outsideClick(e) {
      if (
        !(
          e.target.matches('ul') ||
          e.target.matches('ul li') ||
          e.target.matches('ul li a') ||
          e.target.matches('.dropdown-btn') ||
          e.target.matches('.z-nav-pages')
        )
      ) {
        dropdowns.forEach((interDrop) => {
          interDrop.classList.remove('active');
        });
      }
    }

    document.addEventListener('mouseover', (e) => outsideClick(e));
  }
}

new Navbar();
