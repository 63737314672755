// store images when same root

// takes multiple roots and make for each root a lightbox slideshow
export default class ImageSlidesPopUp {
  constructor(id = '') {
    if (id === '') {
      this.imageSlidesPopUpEls = document.querySelectorAll('.ImageSlidesPopUp');
      if (this.imageSlidesPopUpEls === null) return;
      if (this.imageSlidesPopUpEls.length === 0) {
        return;
      }
      this.imageSlides = [];
      this.imageSlidesPopUpEls.forEach((value, index) => {
        const one = new ImageSlidesOneEl(value, index);
        this.imageSlides.push(one);
      });
    } else {
      this.imageSlidesPopUpOneEl = document.querySelector(id);
      if (this.imageSlidesPopUpOneEl === null) return;
      this.imageSlides = [new ImageSlidesOneEl(this.imageSlidesPopUpOneEl, 0)];
    }
    this.id = id;
    this.current = 0;
    this.lightboxEl = this.createLightBoxEl();
    this.closeBtn = this.lightboxEl.querySelector('.close');
    this.nextBtn = this.lightboxEl.querySelector('.next');
    this.prevBtn = this.lightboxEl.querySelector('.prev');
    this.currentImg = this.lightboxEl.querySelector('img');

    this.setEventListenerPopUp();
    this.lightboxEvents();
    this.keydownEvents();
  }

  createLightBoxEl() {
    if (document.querySelector('#lightbox') !== null) {
      return document.querySelector('#lightbox');
    }
    const lightbox = document.createElement('div');
    lightbox.id = 'lightbox';

    lightbox.innerHTML = `
      <img src="" alt="Varierende Bilder im Bild Pop-up"/>
     <div class="close">
       <i class="far fa-times-circle"></i>
     </div>
     <div class="prev">
       <i class="fas fa-arrow-left"></i>
     </div>
     <div class="next">
       <i class="fas fa-arrow-right"></i>
     </div>
`;

    document.body.appendChild(lightbox);
    return document.querySelector('#lightbox');
  }

  popUpImage(activeImageSlide, image) {
    this.current = activeImageSlide.id;
    this.lightboxEl.classList.add('active');

    if (!image.getAttribute('data-src')) {
      this.currentImg.src = image.src;
    } else {
      this.currentImg.src = image.getAttribute('data-src');
    }
    // if (activeImageSlide.isNordic() === true) {
    //   this.currentImg.src = image.getAttribute('data-src');
    // } else {
    //   this.currentImg.src = image.src;
    // }

    activeImageSlide.images.forEach((value, index) => {
      if (value.src === image.src) {
        activeImageSlide.current = index;
        return;
      }
    });
  }

  setEventListenerPopUp() {
    this.imageSlides.forEach((each) => {
      each.images.forEach((image) => {
        image.addEventListener('click', (e) => {
          this.popUpImage(each, image);
        });
      });
    });
  }

  setNextImage() {
    this.currentImg.src = this.imageSlides[this.current].nextImage();
  }

  setPrevImage() {
    this.currentImg.src = this.imageSlides[this.current].prevImage();
  }

  lightboxEvents() {
    this.closeBtn.addEventListener('click', () => this.lightboxEl.classList.remove('active'));
    this.nextBtn.addEventListener('click', () => this.setNextImage());
    this.prevBtn.addEventListener('click', () => this.setPrevImage());

    this.currentImg.addEventListener('click', (e) => {
      const clickTarget = e.target;
      const clickTargetWidth = clickTarget.offsetWidth;
      const xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left;
      if (clickTargetWidth / 2 > xCoordInClickTarget) {
        this.setPrevImage();
      } else {
        this.setNextImage();
      }

      // this.imageSlides[this.current].getLink();
    });

    this.lightboxEl.addEventListener('click', (e) => {
      if (e.target === this.lightboxEl) {
        this.lightboxEl.classList.remove('active');
      }
    });
  }

  keydownEvents() {
    document.addEventListener('keydown', (e) => {
      if (!this.lightboxEl.classList.contains('active')) {
        return;
      }
      if (e.keyCode == '39') {
        this.setNextImage();
      }
      if (e.keyCode == '37') {
        this.setPrevImage();
      }
      if (e.keyCode == '27') {
        this.lightboxEl.classList.remove('active');
      }
    });
  }
}

// const clickTarget = e.target;
// const clickTargetWidth = clickTarget.offsetWidth;
// const xCoordInClickTarget =
//   e.clientX - clickTarget.getBoundingClientRect().left;
// if (clickTargetWidth / 2 > xCoordInClickTarget) {
// console.log("left");
// } else {
//   console.log("rigt");
// }

class ImageSlidesOneEl {
  constructor(root, id) {
    this.root = root;
    this.id = id;

    this.images = root.querySelectorAll('img');

    this.current = 0;
    this.count = this.images.length;
    this.currentLink = '';
    this.init();
  }
  init() {
    if (this.images.length >= 1) {
      this.currentLink = this.images[0].getAttribute('link_to');
    }
  }
  nextImage() {
    if (this.current + 1 >= this.images.length) {
      this.current = 0;
    } else {
      this.current += 1;
    }

    return this.getSrc();
  }

  prevImage() {
    if (this.current - 1 < 0) {
      this.current = this.images.length - 1;
    } else {
      this.current -= 1;
    }

    return this.getSrc();
  }
  getCount() {
    return this.count;
  }

  getSrc() {
    if (!this.images[this.current].getAttribute('data-src')) {
      return this.images[this.current].src;
    } else {
      return this.images[this.current].getAttribute('data-src');
    }
  }
}

new ImageSlidesPopUp();
