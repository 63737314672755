import './BoxMessage.scss';
import './extension';
import './Handlers.scss';
import './ImageSlidesPopUp.scss';

import BoxMessage from './BoxMessage';
import './ImageSlidesPopUp';
// new ImageSlidesPopUp();

if (document.querySelector('#BoxMessage') !== null) {
  const boxMessageBackend = new BoxMessage();
  setTimeout(() => boxMessageBackend.toggle(), 1000);
}
