/*
Example:
<div class="test link_to" link_to="https://goo.gl/maps/fF7gYyErjSEZpUV57">
<div class="test link_to_blanket" link_to="https://goo.gl/maps/fF7gYyErjSEZpUV57">

<a href="/#kontakt" hrefJump="#kontaktjump" class="jump-to-el">Kontakt</a>
*/
import Standard from '../../js/Libraries/Standard';
import jump from 'jump.js';
import tippy from 'tippy.js';
import Handlers from '../../js/Libraries/Handlers';

export default class Links extends Standard {
  constructor() {
    super();
    this.hl = new Handlers();
    this.link_to = document.querySelectorAll('.link_to');
    this.link_to_blanket = document.querySelectorAll('.link_to_blanket');
    this.allJumpTo = document.querySelectorAll('.jump-to-el');
    this.allTippies = document.querySelectorAll('.tippy-el');

    this.init();
  }

  init() {
    this.setTippy();
    this.events();
  }
  setTippy() {
    /*
    Example:
    <span class="tippy-el" marginTop="100px" tippyText="Unser Expertenteam ...">
          <i class="fas fa-question-circle"></i>
    </span>
*/

    this.allTippies.forEach((tipp) => {
      const text = tipp.getAttribute('tippyText');
      tippy(tipp, {
        content: `<div class="tippy-content">${text}</div>
        `,
        placement: 'top',
        allowHTML: true,
        interactive: true,
      });
    });
  }

  events() {
    this.link_to.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        let link = el.getAttribute('link_to');
        if (link !== null) {
          window.location.href = link;
        }
      });
    });
    this.link_to_blanket.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        let link = el.getAttribute('link_to');
        if (link !== null) {
          window.open(link, '_blank');
        }
      });
    });

    this.allJumpTo.forEach((el) => {
      const ref = el.getAttribute('hrefJump');
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const goal = document.querySelector(ref);
        if (goal !== null) {
          jump(goal, {
            duration: (distance) => Math.min(Math.abs(distance) / 2, 1200),
            // offset: -100,
          });
        } else {
          window.location.href = el.href;
          if (el.href) {
            window.location.href = el.href;
            return;
          }
          let link = el.getAttribute('link_to');
          if (link !== null) {
            window.location.href = link;
            return;
          }
        }
      });
    });
  }
}
