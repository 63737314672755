export default class Handlers {
  constructor() {
    this.create();
    this.overlay = document.querySelector('.loader-overlay');

    this.createMessage();

    this.box = document.querySelector('#MessageInHandler');
    this.btn = this.box.querySelector('.close');
  }
  create() {
    if (document.querySelector('.loader-overlay') === null) {
      const loader = document.createElement('div');
      // loader.innerHTML = "<span></span><span></span><span></span>";
      loader.innerHTML = `      <div class='hollowLoader'>
      <div class='largeBox'></div>
      <div class='smallBox'></div>
    </div>`;
      loader.classList = 'loader-overlay';
      document.body.insertAdjacentElement('afterbegin', loader);
    }
  }

  createMessage() {
    if (document.querySelector('#MessageInHandler') === null) {
      const box = document.createElement('div');
      box.id = 'MessageInHandler';
      box.innerHTML = `
      <div class="content">
        <div class="close"><i class="fas fa-times"></i></div>
        <div class="content-text pt-4"></div>
      </div>`;

      document.body.insertAdjacentElement('afterbegin', box);
    }
  }
  toggle() {
    this.overlay.classList.toggle('active');
  }
  removeToggle() {
    this.overlay.classList.remove('active');
  }

  message(msg = 'Es gibt zurzeit leider ein Problem! Versuche es später noch einmal!') {
    this.box.classList.add('active');
    this.box.querySelector('.content-text').innerHTML = msg;
    function closeBox(self) {
      self.box.classList.remove('active');
    }
    function outsideClick(e, self) {
      if (e.target == self.box) {
        self.box.classList.remove('active');
      }
    }

    this.btn.addEventListener('click', () => closeBox(this));
    document.addEventListener('click', (e) => outsideClick(e, this));
  }
  wait(time = 2000) {
    return new Promise((resolve, reject) => {
      if (false) {
        reject('Looser');
      }
      setTimeout(() => {
        resolve();
      }, time);
    });
  }
  // document.addEventListener("scroll", async () => {})
  elementInViewportAnyPart(el, tolerance = 0) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    top = top - tolerance;
    height = height + tolerance;
    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  }

  widthOfScreen() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }
}
